import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { CATEGORIES_PAGINATION } from 'constants/campaign';
import { getCampaignCategoriesInput } from 'utils/inputs/createCampaignInputs';
import useCancellableTimeout from 'hooks/useCancellableTimeout';
import {
  selectFetchCampaignCategoriesState,
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import {
  clearEstimationsFiltersState,
  fetchNewCampaignEstimations,
} from 'state/actions/campaigns';

import classes from './CampaignCategoriesForm.module.scss';

const CampaignCategoriesForm = () => {
  const dispatch = useDispatch();

  const [categoriesToShow, setCategoriesToShow] = useState([]);
  const [remainingCategories, setRemainingCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [categoriesMap, setCategoriesMap] = useState({});

  const { inTimeout, createTimeout } = useCancellableTimeout();

  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const defaultData = formsData[currentStep] ?? campaign;

  const { categories: persistedCategories } = defaultData || {};

  const { categories } = useSelector(
    selectFetchCampaignCategoriesState,
    shallowEqual
  );

  const { loading, estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const {
    potentialPodcasts,
    estimatedImpressions,
    filters,
  } = estimationsSummary;

  const { categories: categoriesFilter } = filters || {};

  useEffect(() => {
    if (categories.length > 0 && categoriesToShow.length === 0) {
      const categoriesMapLength = Object.keys(categoriesMap).length;
      const allCategories = [];
      const newCategoriesMap = {};

      categories.forEach(({ name, id }) => {
        if (!categoriesMapLength) {
          newCategoriesMap[id] = name;
        }

        allCategories.push({
          label: name,
          value: id,
        });
      });

      setCategoriesMap(newCategoriesMap);

      const firstCategories = allCategories.splice(0, CATEGORIES_PAGINATION);
      setCategoriesToShow(firstCategories);
      setRemainingCategories(allCategories);
    }
  }, [categories, categoriesToShow, categoriesMap]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const onClickMoreCategoriesHandler = useCallback(() => {
    const allRemainingCategories = [...remainingCategories];
    const firstCategories = allRemainingCategories.splice(
      0,
      CATEGORIES_PAGINATION
    );

    setCategoriesToShow((prevState) => [...prevState, ...firstCategories]);
    setRemainingCategories(allRemainingCategories);
  }, [remainingCategories]);

  const onChangeHandler = useCallback(
    (newValues) => {
      if (!newValues.length) {
        setSelectedCategories({});
      }

      newValues.forEach((value) => {
        if (!selectedCategories[value]) {
          setSelectedCategories((prevState) => ({
            ...prevState,
            [value]: categoriesMap[value],
          }));
        }
      });

      createTimeout(() => {
        dispatch(
          fetchNewCampaignEstimations({
            filters: { categories: newValues },
            localFilter: true,
          })
        );
      }, 1500);

      return newValues;
    },
    [createTimeout, selectedCategories, categoriesMap, dispatch]
  );

  const onGoBackHandler = () => {
    if (!persistedCategories?.length) {
      dispatch(clearEstimationsFiltersState({ filters: ['categories'] }));
    }

    goBack();
  };

  useEffect(() => {
    dispatch(fetchNewCampaignEstimations({ localFilter: !!categoriesFilter }));
  }, [dispatch]);

  const campaignCategoriesFields = useMemo(
    () =>
      getCampaignCategoriesInput({
        categories: categoriesToShow,
        defaultValues: defaultData,
        onChangeHandler,
      }),
    [categoriesToShow, defaultData, onChangeHandler]
  );

  const isLoading = inTimeout || loading;

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Choose the categories you want to advertise. Remember that more niche
          categories will mean fewer podcasts applying for your campaign. You
          can skip this step if you wish.
        </Body>
        <div className={classes.fields}>
          {campaignCategoriesFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
          {remainingCategories.length > 0 && (
            <button
              type="button"
              className={classes.categoriesButton}
              onClick={onClickMoreCategoriesHandler}
            >
              See More Categories
            </button>
          )}
        </div>
      </div>

      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        potentialPodcasts={potentialPodcasts}
        estimatedImpressions={estimatedImpressions}
        loading={loading || inTimeout}
      />
      <div className={commonClasses.buttonsContainer}>
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={onGoBackHandler}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignCategoriesForm;
