import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getCampaigAdPlacementInputs } from 'utils/inputs/createCampaignInputs';
import {
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import { fetchNewCampaignEstimations } from 'state/actions/campaigns';
import useCancellableTimeout from 'hooks/useCancellableTimeout';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import validationSchema from './CampaignAdPlacementForm.schema';
import classes from './CampaignAdPlacementForm.module.scss';

const CampaignAdPlacementForm = () => {
  const dispatch = useDispatch();
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const { inTimeout, createTimeout } = useCancellableTimeout();

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const defaultData = formsData[currentStep] ?? campaign;

  const { estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const calculateCampaignEstimations = useCallback(
    (args) => {
      createTimeout(() => {
        dispatch(
          fetchNewCampaignEstimations({ filters: args, localFilter: true })
        );
      }, 1500);
    },
    [createTimeout, dispatch]
  );

  const onChangeDo = useCallback(
    (data, extraData) => {
      const { name } = extraData;

      if (name === 'adPlacements') {
        calculateCampaignEstimations({ adPlacements: data });
      }
    },
    [calculateCampaignEstimations]
  );

  const campaignEthnicityAndMaritalStatusFields = useMemo(
    () =>
      getCampaigAdPlacementInputs({ defaultValues: defaultData, onChangeDo }),
    [defaultData, onChangeDo]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Choose where you want to place your ads.
          <br />
          <a
            href="https://help.ossacollective.com/en/articles/6495887-get-started-guide-for-advertisers#h_0987bc0cd5"
            rel="noreferrer"
            target="_blank"
            className={commonClasses.link}
          >
            Would you like to know more about ad placements?
          </a>
        </Body>
        <div className={classes.fields}>
          {campaignEthnicityAndMaritalStatusFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>

      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        potentialPodcasts={potentialPodcasts}
        estimatedImpressions={estimatedImpressions}
        loading={inTimeout}
      />
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignAdPlacementForm;
