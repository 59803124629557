import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import ProgressBar from 'components/Common/ProgressBar';
import Spinner from 'components/Common/Spinner';
import {
  clearCampaignState,
  createCampaign,
  editCampaign,
  fetchCampaignCategories,
  fetchUnfinishedCampaign,
  fetchExistingCampaigns,
  clearEstimationsFiltersState,
  updateCampaignsTabAction,
} from 'state/actions/campaigns';
import {
  fetchPodcastersCountriesIsoCodes,
  fetchDefaultValues,
} from 'state/actions/generals';
import {
  selectCreateCampaignState,
  selectEditCampaignState,
  selectFetchUnfinishedCampaignState,
  selectFetchCampaignCategoriesState,
  selectFetchExistingCampaignsState,
} from 'state/selectors/campaigns';
import {
  selectFetchPodcastersCountriesIsoCodes,
  selectFetchDefaultValues,
} from 'state/selectors/generals';
import Path from 'enums/path.enum';

import MultiStepForm from 'components/Common/MultiStepForm';
import {
  CampaignNameForm,
  StartAndEndDatesForm,
  CampaignBudgetForm,
  CampaignCategoriesForm,
  GenderAgeAndIncomeForm,
  CampaignEthnicityAndMaritalForm,
  CampaignCountryForm as CampaignCountryAndCityForm,
  CampaignAdPlacementForm,
  CampaignDescriptionForm,
  CampaignTalkingPointsForm,
  CampaignUploadImage,
  CampaignSummaryReview,
} from 'components/Pages/Forms/CreateCampaign';

import { selectVerifyBrandPrivilegesState } from 'state/selectors/auth';
import { verifyBrandPrivileges } from 'state/actions/auth';
import CampaignAdFormatsForm from 'components/Pages/Forms/CreateCampaign/CampaignAdFormatsForm';
import CampaignsTab from 'enums/pages/campaigns/campaignsTab.enum';
import { STATUS_PENDING } from 'utils/Campaign/status';
import classes from './CreateCampaign.module.scss';

const defaultMultiStepFormSteps = [
  CampaignNameForm,
  StartAndEndDatesForm,
  CampaignBudgetForm,
  CampaignCategoriesForm,
  GenderAgeAndIncomeForm,
  CampaignEthnicityAndMaritalForm,
  CampaignCountryAndCityForm,
  CampaignAdPlacementForm,
  CampaignDescriptionForm,
  CampaignTalkingPointsForm,
  CampaignUploadImage,
  CampaignSummaryReview,
];

// the first element of the sub array defines where in the multiStepFormSteps array the step will be placed
const multiStepFormAdditionalSteps = [[2, CampaignAdFormatsForm]];

const CreateCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isSuperUser, loading: verifyingBrandPrivileges } = useSelector(
    selectVerifyBrandPrivilegesState,
    shallowEqual
  );

  const multiStepFormSteps = useMemo(() => {
    if (isSuperUser) {
      const newMultiStepFormSteps = [...defaultMultiStepFormSteps];
      multiStepFormAdditionalSteps.forEach(([stepIndex, StepComponent]) => {
        newMultiStepFormSteps.splice(stepIndex, 0, StepComponent);
      });
      return newMultiStepFormSteps;
    }
    return defaultMultiStepFormSteps;
  }, [isSuperUser]);

  const progressStep = useMemo(() => 100 / (multiStepFormSteps.length + 1), []);

  const [progress, setProgress] = useState(progressStep);

  const { error: errorCreating, success: successCreating } = useSelector(
    selectCreateCampaignState,
    shallowEqual
  );

  const { error: errorFetching, success: successFetching } = useSelector(
    selectFetchExistingCampaignsState,
    shallowEqual
  );

  const { error: errorEditing, success: successEditing } = useSelector(
    selectEditCampaignState,
    shallowEqual
  );

  const { loading: loadingCategories } = useSelector(
    selectFetchCampaignCategoriesState,
    shallowEqual
  );

  const { loading: fetchPodcastersIsoCodesLoading } = useSelector(
    selectFetchPodcastersCountriesIsoCodes,
    shallowEqual
  );

  const { defaultValues } = useSelector(selectFetchDefaultValues, shallowEqual);

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const { categories: allCategories } = useSelector(
    selectFetchCampaignCategoriesState,
    shallowEqual
  );

  useEffect(() => {
    dispatch(verifyBrandPrivileges());
    dispatch(fetchCampaignCategories());
    dispatch(fetchUnfinishedCampaign());

    return () => {
      dispatch(clearCampaignState());
      dispatch(clearEstimationsFiltersState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (successCreating || successEditing) {
      dispatch(fetchExistingCampaigns());
    }
  }, [dispatch, successCreating, successEditing]);

  useEffect(() => {
    const completedFetching = errorFetching || successFetching;
    const formSuccess = successCreating || successEditing;

    if (completedFetching && formSuccess) {
      history.push(Path.Campaigns);
    }
  }, [
    errorFetching,
    successFetching,
    successCreating,
    successEditing,
    history,
  ]);

  useEffect(() => {
    dispatch(fetchCampaignCategories());
    dispatch(fetchPodcastersCountriesIsoCodes());
    dispatch(fetchDefaultValues());
  }, [dispatch]);

  const onSubmitHandler = useCallback(
    (values) => {
      const parsedCategories = allCategories.filter((category) =>
        values.categories.includes(category.id)
      );

      const { cpmRate, numberOfEpisodes, adFormats } = values;
      const { splitBudget, ...newValues } = values;

      if (
        splitBudget &&
        splitBudget.podcaster !== defaultValues.percentages.podcaster
      ) {
        newValues.percentages = {
          podcaster: splitBudget.podcaster,
          network: splitBudget.network,
        };
      } else {
        newValues.percentages = {
          podcaster: defaultValues.percentages.podcaster,
          network: defaultValues.percentages.network,
        };
      }

      const fullValues = {
        ...newValues,
        cpmRate: cpmRate >= 0 ? cpmRate : defaultValues.cpmRate,
        numberOfEpisodes: numberOfEpisodes || null,
        categories: parsedCategories,
        adFormats: adFormats ? [adFormats] : [],
        finishedCreatingCampaign: true,
        isPaid: false,
        status: STATUS_PENDING,
      };

      dispatch(updateCampaignsTabAction(CampaignsTab.YourOffers));
      setIsLoading(true);
      setProgress((prevState) => prevState + progressStep);
      if (campaign) {
        dispatch(
          editCampaign({
            campaignId: campaign.uid,
            campaign: fullValues,
          })
        );
      } else {
        fullValues.offers = [];
        dispatch(
          createCampaign({
            campaign: fullValues,
          })
        );
      }
    },
    [allCategories, campaign, dispatch, defaultValues]
  );

  const onBackward = useCallback(() => {
    setProgress((prevState) => prevState - progressStep);
  }, [progressStep]);

  const onForward = useCallback(() => {
    setProgress((prevState) => prevState + progressStep);
  }, [progressStep]);

  const loading =
    loadingCategories ||
    fetchPodcastersIsoCodesLoading ||
    verifyingBrandPrivileges;

  return (
    <>
      {errorCreating && (
        <Toast text={errorCreating} id="Create Campaign Error" />
      )}
      {errorEditing && <Toast text={errorEditing} id="Edit Campaign Error" />}
      <div className={classes.container}>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Create a campaign
        </Body>
        <ProgressBar value={progress} />
        <div className={classes.content}>
          {loading || isLoading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <MultiStepForm
              steps={multiStepFormSteps}
              onSubmit={onSubmitHandler}
              onBackward={onBackward}
              onForward={onForward}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateCampaign;
