import { combineReducers } from '@reduxjs/toolkit';

import authReducer from 'state/reducers/auth';
import usersReducer from 'state/reducers/users';
import campaignsReducer from 'state/reducers/campaigns';
import stripeReducer from 'state/reducers/stripe';
import settingsReducer from 'state/reducers/settings';
import generalsReducer from 'state/reducers/generals';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  campaigns: campaignsReducer,
  stripe: stripeReducer,
  settings: settingsReducer,
  generals: generalsReducer,
});

export default rootReducer;
