import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes, { elementType } from 'prop-types';

const MultiStepFormContext = createContext(null);

const useMultiStepFormProgress = ({ onForward, onBackward }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const goForward = () => {
    setCurrentStep((prevState) => prevState + 1);
    onForward();
  };

  const goBack = () => {
    setCurrentStep((prevState) => prevState - 1);
    onBackward();
  };

  return [currentStep, goForward, goBack];
};

const MultiStepForm = ({
  steps,
  onSubmit,
  onForward,
  onBackward,
  pageProps,
}) => {
  const [formsData, setFormsData] = useState({});
  const [currentStep, goForward, goBack] = useMultiStepFormProgress({
    onForward,
    onBackward,
  });

  const CurrentStep = steps[currentStep];

  const onSubmitForms = useCallback(
    (data) => {
      const isLastStep = currentStep === steps.length - 1;

      if (isLastStep) {
        const submitData = {
          ...data,
        };

        Object.values(formsData).forEach((formData) => {
          Object.entries(formData).forEach(([key, value]) => {
            submitData[key] = value;
          });
        });

        return onSubmit(submitData);
      }

      return setFormsData((prevState) => ({
        ...prevState,
        [currentStep]: data,
      }));
    },
    [currentStep, steps.length, formsData, onSubmit]
  );

  const providerState = useMemo(
    () => ({
      goBack,
      formsData,
      goForward,
      currentStep,
      setFormsData,
      onSubmit: onSubmitForms,
    }),
    [currentStep, formsData, onSubmitForms, goBack, goForward]
  );

  return (
    <MultiStepFormContext.Provider value={providerState}>
      <CurrentStep
        currentStep={currentStep}
        goForward={goForward}
        goBack={goBack}
        onSubmit={onSubmitForms}
        {...pageProps}
      />
    </MultiStepFormContext.Provider>
  );
};

MultiStepForm.propTypes = {
  steps: PropTypes.arrayOf(elementType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onForward: PropTypes.func,
  onBackward: PropTypes.func,
  pageProps: PropTypes.shape({}),
};

MultiStepForm.defaultProps = {
  onForward: () => {},
  onBackward: () => {},
  pageProps: {},
};

export const useMultiStepFormContext = () => useContext(MultiStepFormContext);

export default MultiStepForm;
