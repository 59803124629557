import Form from 'components/Common/Form';
import classNames from 'classnames';
import UploadFile from 'components/Common/UploadFile';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import { useState } from 'react';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import { shallowEqual, useSelector } from 'react-redux';
import { selectFetchEstimationsInCampaignState } from 'state/selectors/campaigns';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import classes from './CampaignUploadImage.module.scss';

const CampaignUploadImage = () => {
  const { onSubmit, goBack, goForward } = useMultiStepFormContext();

  const [campaignImage, setCampaignImage] = useState(null);

  const onSubmitHandler = () => {
    onSubmit({ image: campaignImage });
    goForward();
  };

  const { estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );
  const onChangeLogoHandler = (file) => {
    setCampaignImage(file);
  };

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Campaign Image
        </Body>
        <div className={classes.fields}>
          <UploadFile
            name="image"
            selectedFile={campaignImage}
            setSelectedFile={onChangeLogoHandler}
          />
        </div>
      </div>
      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        potentialPodcasts={potentialPodcasts}
        estimatedImpressions={estimatedImpressions}
      />
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={campaignImage === null}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignUploadImage;
