import React from 'react';
import { getName } from 'country-list';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Badge from 'components/Common/Badge';
import classes from 'components/Pages/Campaign/Summary/Summary.module.scss';
import parse from 'html-react-parser';
import adFormats from 'utils/Campaign/adFormats';
import incomeOptions from 'utils/Campaign/incomeOptions';
import { ageOptions } from '../Campaign/ageOptions';
import ethnicityOptions from '../Campaign/ethnicityOptions';
import adTypeInputs from './addTypeInputs';

const getCampaignSummaryFields = (campaign, categories) => [
  {
    label: 'Name',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {campaign.name || ''}
      </Body>
    ),
  },
  {
    label: 'Ethnicities',
    component: (props) => (
      <>
        {campaign?.ethnicity?.length ? (
          campaign.ethnicity.map((ethnicityValue) => (
            <Body {...props} size={BodySize.XS}>
              {
                ethnicityOptions.find(
                  (ethnicityOption) => ethnicityValue === ethnicityOption.value
                ).label
              }
            </Body>
          ))
        ) : (
          <Body {...props} size={BodySize.XS}>
            -
          </Body>
        )}
      </>
    ),
  },
  {
    label: 'Budget',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {`$${formatNumberWithComas(campaign?.budget)}` || '-'}
      </Body>
    ),
  },
  {
    label: 'Income',
    component: (props) => (
      <>
        {campaign?.income?.length
          ? campaign.income.map((income) => (
              <Body {...props} size={BodySize.XS}>
                {
                  incomeOptions.find(
                    (incomeOption) => incomeOption.value === income
                  )?.label
                }
              </Body>
            ))
          : '-'}
      </>
    ),
  },
  {
    label: 'Airing Between',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {campaign.airingFrom?.toDateString() || ''} -{' '}
        {campaign.airingTo?.toDateString() || ''}
      </Body>
    ),
  },
  {
    label: 'Marital Status',
    component: (props) => (
      <>
        {campaign?.maritalStatus?.length ? (
          campaign.maritalStatus.map((maritalStatus) => (
            <Body {...props} size={BodySize.XS}>
              {maritalStatus}
            </Body>
          ))
        ) : (
          <Body {...props} size={BodySize.XS}>
            -
          </Body>
        )}
      </>
    ),
  },

  {
    label: 'Categories',
    component: (props) => (
      <>
        {categories.filter((category) =>
          campaign?.categories.includes(category.id)
        )?.length ? (
          categories
            .filter((category) => campaign.categories.includes(category.id))
            ?.map(({ id, name }) => (
              <Badge
                {...props}
                text={name}
                className={classes.category}
                key={id}
              />
            ))
        ) : (
          <Body {...props} size={BodySize.XS}>
            -
          </Body>
        )}
      </>
    ),
  },
  {
    label: 'Countries',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {campaign?.countries?.length
          ? campaign.countries.map((countryCode) => (
              <>
                <Body {...props} size={BodySize.XS}>
                  {getName(countryCode) || ''}
                </Body>
              </>
            ))
          : '-'}
      </Body>
    ),
  },
  {
    label: 'Gender',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        Male: {campaign.gender?.male || '0'} % <br />
        Female: {campaign.gender?.female || '0'} %
      </Body>
    ),
  },
  {
    label: 'CPM',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {campaign?.cpmRate || '-'}
      </Body>
    ),
  },
  {
    label: 'Age Ranges',
    component: (props) => (
      <>
        {campaign?.age?.length ? (
          campaign.age.map((ageValue) => (
            <Body {...props} key={ageValue}>
              {ageOptions.find((ageOption) => ageValue === ageOption.value)
                ?.label || ''}
            </Body>
          ))
        ) : (
          <Body {...props} size={BodySize.XS}>
            -
          </Body>
        )}
      </>
    ),
  },

  {
    label: 'Split',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        Network: {Math.round(campaign.splitBudget?.network * 100)} % <br />
        Podcaster: {Math.round(campaign.splitBudget?.podcaster * 100)} %
      </Body>
    ),
  },
  {
    label: 'Ad Placements',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {campaign?.adPlacements?.length
          ? campaign.adPlacements.map((adTypeValue) => (
              <Body {...props} size={BodySize.XS}>
                {adTypeInputs.find((adType) => adType.type === adTypeValue)
                  .label || '-'}
              </Body>
            ))
          : '-'}
      </Body>
    ),
  },
  {
    label: campaign?.adFormats ? 'Ad Formats' : '',
    component: (props) =>
      campaign?.adFormats ? (
        <Body {...props} size={BodySize.XS}>
          {
            adFormats.find(
              (addFormat) => addFormat.value === campaign?.adFormats
            )?.label
          }
        </Body>
      ) : null,
  },
];

const getCampaignSummaryFieldsFullWidth = (campaign) => [
  {
    label: 'Description',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {parse(campaign.description || '')}
      </Body>
    ),
  },
  {
    label: 'Talking Points',
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {parse(campaign.talkingPoints)}
      </Body>
    ),
  },
];
export { getCampaignSummaryFields, getCampaignSummaryFieldsFullWidth };
